import React, { useState } from 'react';
import './PricingYourHome.css';

const PricingYourHome = () => {
  const [searchParams, setSearchParams] = useState({
    minPrice: '',
    maxPrice: '',
    search: ''
  });

  const cities = [
    'Toronto', 'Mississauga', 'Brampton', 'Oakville', 'Hamilton', 
    'Vaughan', 'Waterloo', 'London', 'Markham', 'Ajax', 'Aurora', 
    'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 
    'Clarington', 'Collingwood', 'Erin', 'Georgina', 'Grimsby', 
    'Innisfil', 'King', 'Kingston', 'Lincoln', 'Midland', 'Ottawa', 
    'Milton', 'Newmarket', 'Niagara-on-the-lake', 'Orangeville', 
    'Orillia', 'Oshawa', 'Peterborough', 'Pickering', 'Tiny', 
    'Welland', 'Whitby', 'Whitchurch'
  ];

  const styles = {
    container: {
      maxWidth: '1500px',
      margin: '0 auto',
      padding: '0rem 0rem',
    },
    grid: {
      gap: '2rem',
    },
    mainContent: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '1rem',
      marginBottom: '0rem',
    },
    sidebarCard: {
      background: '#f3f4f6',
      borderRadius: '8px',
      padding: '1.5rem',
      marginBottom: '1rem',
    },
    sidebar: {
      position: 'sticky',
      top: '1rem',
      marginTop: '2rem',
      width: '300px',
      minWidth: '300px'
    },
    list: {
      listStyleType: 'none',
      marginBottom: '1rem',
      paddingLeft: '0',
      fontSize: '1rem',
      lineHeight: '1.5', 
    },

    priceInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      marginBottom: '0.5rem',
    },
    priceInput: {
      width: '100%',
      padding: '0.75rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchInput: {
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0rem',
      borderRadius: '0.25rem',
      border: '1px solid #e2e8f0',
      backgroundColor: 'white',
      fontSize: '1rem',
    },
    searchButton: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      border: 'none',
      width: '100%',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    button: {
      display: 'inline-block',
      backgroundColor: '#2563eb',
      color: 'white',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '1.25rem',
      fontWeight: '500',
    },
    cityList: {
      marginTop: '1rem',
    },
    cityLink: {
      display: 'block',
      padding: '0.5rem',
      color: '#2563eb',
      textDecoration: 'none',
      fontSize: '1.25rem',
      borderRadius: '0.25rem',
      '&:hover': {
        backgroundColor: '#f0f9ff',
      }
    }
  };

  const ListItem = ({ children }) => (
    <li className="pricing-list-item" style={{
      marginBottom: '0.5rem',
      paddingLeft: '24px',
      position: 'relative',
      fontSize: '1.25rem', // Match body text size
      lineHeight: '2', // Match body text line height
    }}>
      <span className="pricing-bullet-outer" style={{
        position: 'absolute',
        left: '0',
        top: '14px', // Adjusted to vertically align with text
        width: '14px',
        height: '14px',
        border: '1px solid #9CDCF8',
        borderRadius: '50%',
        display: 'block'
      }}></span>
      <span className="pricing-bullet-inner" style={{
        position: 'absolute',
        left: '4px',
        top: '18px', // Adjusted to vertically align with text
        width: '6px',
        height: '6px',
        backgroundColor: '#9CDCF8',
        borderRadius: '50%',
        display: 'block'
      }}></span>
      {children}
    </li>
  );

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams({
      city: 'All',
      community: 'null',
      minPrice: searchParams.minPrice || '0',
      maxPrice: searchParams.maxPrice || '10000000',
      bedrooms: 'Any',
      bathrooms: 'Any',
      search: searchParams.search || '',
      page: '1'
    }).toString();
    window.location.href = `/listings?${queryString}`;
  };

  const handleInputChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="pricing-container" style={styles.container}>
      <div className="pricing-grid" style={styles.grid}>
        <div className="pricing-main-content" style={styles.mainContent}>
          <section className="pricing-hero-card" style={styles.card}>
            <h1 className="pricing-title" style={styles.title}>Pricing Your Home with Modern Solution Realty</h1>
            <p className="pricing-text" style={styles.paragraph}>
              Pricing your home effectively is both an art and a science. At Modern Solution Realty, 
              we know that achieving the ideal price requires a combination of objective research into 
              comparable properties and a deep understanding of current market trends, along with a 
              focus on your home's unique features.
            </p>
          </section>

          <div className="pricing-content-card" style={styles.card}>
            <h2 className="pricing-subtitle" style={styles.subtitle}>Why the Right Price Matters</h2>
            <p className="pricing-text" style={styles.paragraph}>A well-priced home:</p>
            <ul className="pricing-list" style={styles.list}>
              <ListItem>Attracts buyers.</ListItem>
              <ListItem>Maximizes your earnings.</ListItem>
              <ListItem>Sells quickly.</ListItem>
            </ul>
            <p className="pricing-text" style={styles.paragraph}>
              Price is the primary factor most buyers consider when choosing which homes to view. 
              While you set the price, the value is ultimately determined by the buyer. Overpricing 
              is a common mistake that can cost you both time and money, especially in today's market.
            </p>
            <p className="pricing-text" style={styles.paragraph}>
              In the current real estate climate, overpriced homes tend to linger on the market, 
              which can harm a property's appeal and reduce buyer interest over time. Proper pricing 
              ensures your home gets the attention it deserves from serious buyers.
            </p>
          </div>

          <div className="pricing-benefits-card" style={styles.card}>
            <h2 className="pricing-subtitle" style={styles.subtitle}>Benefits of Proper Pricing</h2>
            <ul className="pricing-list" style={styles.list}>
              <ListItem>Faster sale with fewer disruptions to your life.</ListItem>
              <ListItem>Increased visibility among active buyers.</ListItem>
              <ListItem>Greater interest from REALTORS® and serious buyers.</ListItem>
              <ListItem>More impactful marketing, including advertising and signage.</ListItem>
              <ListItem>Higher offers and better net proceeds.</ListItem>
              <ListItem>Avoids the stigma of a home sitting too long on the market.</ListItem>
            </ul>
            <p className="pricing-text" style={styles.paragraph}>
              When your home is priced right, it stands out compared to other listings and 
              recently sold properties in your area.
            </p>
          </div>

          <div className="pricing-overpricing-card" style={styles.card}>
            <h2 className="pricing-subtitle" style={styles.subtitle}>Common Reasons for Overpricing</h2>
            <ul className="pricing-list" style={styles.list}>
              <ListItem>Emotional attachment or costly over-improvements.</ListItem>
              <ListItem>Pricing based on what you "need" instead of market conditions.</ListItem>
              <ListItem>Over-reliance on assessed value or lack of accurate data.</ListItem>
              <ListItem>Adding "room to negotiate" without market justification.</ListItem>
            </ul>
          </div>

          <div className="pricing-role-card" style={styles.card}>
            <h2 className="pricing-subtitle" style={styles.subtitle}>The Role of Modern Solution Realty</h2>
            <p className="pricing-text" style={styles.paragraph}>
              At Modern Solution Realty, we're here to guide you through the pricing process to 
              position your home for success.
            </p>
            
            <h3 className="pricing-subtitle" style={styles.subtitle}>1. Comprehensive Market Analysis (CMA)</h3>
            <p className="pricing-text" style={styles.paragraph}>
              We conduct a detailed comparison of recent sales, active listings, and market trends 
              to determine the optimal pricing strategy.
            </p>
            
            <h3 className="pricing-subtitle" style={styles.subtitle}>2. Local Expertise</h3>
            <p className="pricing-text" style={styles.paragraph}>
              Our full-time, local REALTORS® fully understand the nuances of the market in your area. 
              Their insight ensures your home is priced right to attract serious buyers and generate 
              maximum interest.
            </p>
            
            <h3 className="pricing-subtitle" style={styles.subtitle}>3. Collaborative Pricing</h3>
            <ul className="pricing-list" style={styles.list}>
              <ListItem>You make the final decision, factoring in your goals, timeline, and property condition.</ListItem>
              <ListItem>We ensure your home is marketed effectively to attract the right buyers.</ListItem>
            </ul>
          </div>

          <div className="pricing-cta-card" style={{...styles.card, textAlign: 'center'}}>
            <p className="pricing-text" style={styles.paragraph}>
              Let Modern Solution Realty help you price your home strategically, ensuring a smooth 
              sale that maximizes your value.
            </p>
            <a href="/contact" className="pricing-cta-button">
              Contact us today to get started!
            </a>
          </div>
        </div>

        <div className="pricing-sidebar" style={styles.sidebar}>
          <div className="pricing-search-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search Listings</h3>
            <form onSubmit={handleSearch}>
              <div className="pricing-price-inputs" style={styles.priceInputContainer}>
                <div>
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.minPrice}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="pricing-input"
                    style={styles.priceInput}
                    value={searchParams.maxPrice}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                type="text"
                name="search"
                placeholder="Search by Address"
                className="pricing-search-input"
                style={styles.searchInput}
                value={searchParams.search}
                onChange={handleInputChange}
              />
              <button type="submit" className="pricing-search-button">
                Search
              </button>
            </form>
          </div>

          <div className="pricing-cities-card" style={styles.sidebarCard}>
            <h3 className="pricing-sidebar-title" style={styles.subtitle}>Search by City</h3>
            <div className="city-list" style={styles.cityList}>
              {cities.map((city) => (
                <a
                  key={city}
                  href={`/listings?city=${city}`}
                  className="city-link"
                  style={styles.cityLink}
                >
                  {city}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingYourHome;