import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon1 from '../assets/images/icon-1.webp';
import icon2 from '../assets/images/icon-2.webp';
import icon3 from '../assets/images/icon-3.webp';
import icon4 from '../assets/images/icon-4.webp';
import './LandingPage-Selling.css';

const LandingPage = () => {
  const [buttonSet, setButtonSet] = useState('default');
  const navigate = useNavigate();

  const handleBuyClick = () => {
    setButtonSet('buy');
  };

  const handleSellClick = () => {
    setButtonSet('sell');
  };

  const handleContactClick = () => {
    navigate('/contact');
  };

  const handleBackClick = () => {
    setButtonSet('default');
  };

  // Navigation paths for the buying and selling options
  const handleReadyToBuyClick = () => {
    navigate('/contact');
  };

  const handleBrowseListingsClick = () => {
    navigate('/search');
  };

  const handleReadyToSellClick = () => {
    navigate('/contact');
  };

  const handleCommissionInfoClick = () => {
    navigate('/commission-explained');
  };

  return (
    <div className="landing-page">
      <header className="landing-page-header">
        <h1 className="landing-page-title">Modern Solution Realty</h1>
        <h2 className="landing-page-subtitle">Looking to buy or sell a home?</h2>
      </header>
      
      <div className="landing-page-content">
        {buttonSet === 'default' && (
          <>
            <button onClick={handleBuyClick} className="landing-page-button">Buy a Home</button>
            <button onClick={handleSellClick} className="landing-page-button">Sell Your Home</button>
            <button onClick={handleContactClick} className="landing-page-button">Get a free home evaluation</button>
            <button onClick={handleContactClick} className="landing-page-button">Send us a message</button>
            <button onClick={() => window.location.href = 'tel:9058975000'} className="landing-page-button">
  Give us a call at 905-897-5000
</button>
          </>
        )}
        {buttonSet === 'buy' && (
          <>
            <button onClick={handleReadyToBuyClick} className="landing-page-button">I'm ready to buy</button>
            <button onClick={handleBrowseListingsClick} className="landing-page-button">I want to browse listings in my area</button>
            <button onClick={handleContactClick} className="landing-page-button">I'd like to speak to an agent</button>
          </>
        )}
        {buttonSet === 'sell' && (
          <>
            <button onClick={handleReadyToSellClick} className="landing-page-button">I'm ready to sell</button>
            <button onClick={handleContactClick} className="landing-page-button">I'd like to speak to an agent</button>
            <button onClick={handleCommissionInfoClick} className="landing-page-button">I'd like to know more about 1% Commission</button>
          </>
        )}

        {buttonSet !== 'default' && (
          <button onClick={handleBackClick} className="landing-page-button back-button-custom">
            Back
          </button>
        )}
      </div>

      {/* Icon Boxes */}
      <div className="icon-boxes-row">
        <div className="icon-box">
          <img src={icon1} alt="Icon 1" className="icon-box-image" />
          <h3 className="icon-box-heading">2500+ Properties Sold</h3>
        </div>
        <div className="icon-box">
          <img src={icon2} alt="Icon 2" className="icon-box-image" />
          <h3 className="icon-box-heading">$1.1M Buyers Received in Rebates</h3>
        </div>
        <div className="icon-box">
          <img src={icon3} alt="Icon 3" className="icon-box-image" />
          <h3 className="icon-box-heading">$20M Sellers Saved on Commission</h3>
        </div>
        <div className="icon-box">
          <img src={icon4} alt="Icon 4" className="icon-box-image" />
          <h3 className="icon-box-heading">5/5 Stars - 224 Reviews</h3>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
