import React, { useState, useEffect } from 'react';
import './Sell.css';
import bgImage from '../assets/images/sell.webp';
import rightImage from '../assets/images/right-image.jpg';
import GoogleReviews from './GoogleReviews';
import ContactForm from './ContactForm';
import CommissionCalculator from './CommissionCalculator';
import Plotly from 'plotly.js-basic-dist';
import houseIcon from '../assets/icons/house.svg';
import cashbackIcon from '../assets/icons/cashback.svg';
import soldIcon from '../assets/icons/sold.svg';
import buyersReceivedIcon from '../assets/icons/buyers-received.svg';
import sellersSavedIcon from '../assets/icons/sellers-saved.svg';
import ListingModule from './ListingModule';
import hannahThumbnail from '../assets/images/hannah.webp';
import msrlogo from '../assets/images/msr-logo.webp';
import './SellingPage.css';

import slide1 from '../assets/images/1.webp';
import slide2 from '../assets/images/2.webp';
import slide3 from '../assets/images/3.webp';
import slide4 from '../assets/images/4.webp';
import slide5 from '../assets/images/5.webp';
import slide6 from '../assets/images/6.webp';
import slide7 from '../assets/images/7.webp';
import slide8 from '../assets/images/8.webp';

import thumb1 from '../assets/images/thumb1.jpg';
import thumb2 from '../assets/images/thumb2.jpg';
import thumb3 from '../assets/images/thumb3.jpg';
import thumb4 from '../assets/images/thumb4.jpg';
import thumb5 from '../assets/images/thumb5.jpg';

import CallToActionBottomRight from './CallToAction-BottomRight';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import image1 from '../assets/images/advertising-1.webp';
import image2 from '../assets/images/advertising-2.webp';
import image3 from '../assets/images/advertising-3.webp';
import image4 from '../assets/images/advertising-4.webp';
import image5 from '../assets/images/advertising-5.webp';
import image6 from '../assets/images/advertising-6.webp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const iconMap = {
  '1% Listing for Sellers': houseIcon,
  '$5,000 Cashback for buyers': cashbackIcon,
  '2,500+ Properties Sold': soldIcon,
  '$1,100,000+ buyers received in cashback': buyersReceivedIcon,
  '$20,150,000+ sellers saved on commission': sellersSavedIcon,
};

const listingNumbers = ['W8491632', 'C8491626', 'N8491622', 'W8491620'];

const SellingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [lightboxVideo, setLightboxVideo] = useState(null);

  const slides = [
    {
      image: slide1,
      title: 'A Home Evaluation and Comparative Market Analysis',
      text: 'Our low-commission real estate agents will assess your home, providing a free and obligation-free written appraisal with the current market value.',
    },
    {
      image: slide2,
      title: 'Marketing Strategy',
      text: 'Tailoring the strategy to your property, our agents will analyze its unique features and devise a targeted marketing approach while maintaining our promise of listing your property for 1%.',
    },
    {
      image: slide3,
      title: 'Showing Management',
      text: 'At Modern Solution Realty, our office manages all of your showings and confirms times that work around your busy schedule. Tip: If you want to sell your property, you should allow access to as many people through the property to gain maximum exposure.',
    },
    {
      image: slide4,
      title: 'The Implementation of Marketing Tactics',
      text: 'We adopt a hands-on approach to every 1% listing, utilizing a range of techniques and services to secure the highest price for your property in the shortest time possible.',
    },
    {
      image: slide5,
      title: 'Negotiating Offers',
      text: 'You won’t be alone on deciding what to do when you receive an offer. Our team of agents are highly trained negotiators and will be with you every step of the way, in your corner to get the right price, terms and conditions – in short, to get the right deal for you!',
    },
    {
      image: slide6,
      title: 'Full MLS Exposure',
      text: 'Your property is seen through every avenue. Listed on all regional boards, the Toronto Regional Real Estate Board, House Sigma, Realtor.ca, and all platforms.',
    },
    {
      image: slide7,
      title: 'Sale and Closing',
      text: 'We pride ourselves on offering top-notch service from start to finish. Our realtors are available 24/7 to advise and address all your concerns. Our commitment to a great, hassle-free selling experience remains unchanged, even with your 1% listing.',
    },
    {
      image: slide8,
      title: 'Call Modern Solution',
      text: 'Call Modern Solution Realty if you have any questions about buying or selling a home. We’d be happy to help with any of your real estate related matters.',
    },
  ];

  const testimonials = [
    {
      video: 'https://www.youtube.com/embed/yDZJJR6ZwBM',
      thumbnail: thumb1,
    },
    {
      video: 'https://www.youtube.com/embed/OvXFtuix_pA',
      thumbnail: thumb2,
    },
    {
      video: 'https://www.youtube.com/embed/_SULRsYheCw',
      thumbnail: thumb3,
    },
    {
      video: 'https://www.youtube.com/embed/x_tvSArv2Y8',
      thumbnail: thumb4,
    },
    {
      video: 'https://www.youtube.com/embed/rv4wxCYZI_w',
      thumbnail: thumb5,
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  const nextTestimonial = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      (currentTestimonial - 1 + testimonials.length) % testimonials.length
    );
  };

  const selectSlide = (index) => {
    setCurrentSlide(index);
  };

  const selectTestimonial = (index) => {
    setCurrentTestimonial(index);
  };

  const openLightbox = (videoUrl) => {
    setLightboxVideo(videoUrl);
  };

  const closeLightbox = () => {
    setLightboxVideo(null);
  };

  useEffect(() => {
    const slidesContainer = document.querySelector('.selling-process-slides');
    if (slidesContainer) {
      slidesContainer.style.transform = `translateX(-${currentSlide * 100}%)`;
    }
  }, [currentSlide]);

  useEffect(() => {
    const testimonialContainer = document.querySelector('.testimonial-slides');
    if (testimonialContainer) {
      testimonialContainer.style.transform = `translateX(-${
        currentTestimonial * 100
      }%)`;
    }
  }, [currentTestimonial]);

  useEffect(() => {
    const statsContainer = document.querySelector('.stats-container');
    if (statsContainer) {
      setTimeout(() => {
        statsContainer.classList.add('show');
      }, 1000);
    }
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    var allYears = Array.from({ length: 34 }, (x, i) => 1990 + i);
    var allOriginalPrices = [
      255020, 234313, 214971, 206490, 205448, 200220, 197760, 210695, 216017,
      228372, 243255, 251508, 275231, 293067, 315231, 335907, 351941, 376236,
      379080, 395234, 431262, 464989, 497073, 522951, 566611, 622116, 729824,
      822510, 787842, 819153, 929636, 1095475, 1190749, 1126568,
    ];

    var startIndex = allYears.findIndex((year) => year >= 2000);
    var years = allYears.slice(startIndex);
    var originalPrices = allOriginalPrices.slice(startIndex);

    var twoPercentData = originalPrices.map((price) => price * 0.01);
    var fivePercentData = originalPrices.map((price) => price * 0.025);
    var savingsData = fivePercentData.map(
      (value, index) => value - twoPercentData[index]
    );

    var trace2 = {
      x: years,
      y: twoPercentData,
      type: 'bar',
      name: 'Modern Solution 1% Commission',
      marker: {
        color: '#66c4db',
      },
      hovertemplate:
        '2.5% Commission (Outdated Commission Model): $%{customdata[1]:,.2f}<br>' +
        'Modern Solution 1% Commission: $%{y:,.2f}<br>' +
        '<br><b>On Average, Modern Solution Clients Saved: $%{customdata[0]:,.2f}</b><extra></extra>',
      customdata: savingsData.map((save, index) => [
        save,
        fivePercentData[index],
      ]),
    };

    var trace3 = {
      x: years,
      y: fivePercentData,
      type: 'bar',
      name: '2.5% Commission (Outdated Commission Model)',
      marker: {
        color: '#252525',
      },
      hoverinfo: 'skip',
    };

    var layout = {
      barmode: 'overlay',
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      yaxis: {
        title: 'Commission Amount',
        automargin: true,
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: 1.1,
        xanchor: 'center',
        yanchor: 'bottom',
        font: {
          size: 16,
        },
      },
      margin: {
        r: 10,
      },
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
    };

    Plotly.newPlot('myCommissionChart', [trace3, trace2], layout, {
      responsive: true,
      toImageButtonOptions: {
        format: 'png',
        filename: 'custom_image',
        height: 600,
        scale: 1,
      },
      modeBarButtonsToRemove: [
        'zoom2d',
        'pan2d',
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'autoScale2d',
        'resetScale2d',
      ],
    });

    var averageHomePriceTrace = {
      x: years,
      y: originalPrices,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Average Home Price',
      line: {
        color: '#252525',
      },
      marker: {
        color: '#252525',
        size: 8,
      },
      hovertemplate:
        'Year: %{x}<br>' + 'Average Home Price: $%{y:,.2f}<extra></extra>',
    };

    var layout2 = {
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
      },
      yaxis: {
        title: 'Average Home Price ($)',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
        tickformat: '$,',
        separatethousands: true,
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.2,
        xanchor: 'center',
        yanchor: 'top',
      },
      margin: {
        t: 40,
        b: 80,
        r: 0,
        l: 120,
      },
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
    };

    Plotly.newPlot('homePriceChart', [averageHomePriceTrace], layout2, {
      responsive: true,
    });
  }, []);

  useEffect(() => {
    const counters = document.querySelectorAll('.counter');
    const speed = 2000;
  
    const maxTarget = Math.max(...Array.from(counters).map(counter => +counter.getAttribute('data-target')));
  
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const counter = entry.target;
            const target = +counter.getAttribute('data-target');
            const inc = maxTarget / speed;
  
            const updateCount = () => {
              const count = +counter.innerText.replace(/\+/g, '');
  
              if (count < target) {
                counter.innerText = Math.ceil(count + inc).toLocaleString();
                setTimeout(updateCount, 1);
              } else {
                counter.innerText = target.toLocaleString() + '+';
              }
            };
  
            updateCount();
            observer.unobserve(counter);
          }
        });
      },
      { threshold: 0.5 }
    );
  
    counters.forEach(counter => {
      counter.style.opacity = 0;
      observer.observe(counter);
    });
  }, []);
  

  useEffect(() => {
    const counters = document.querySelectorAll('.counter');

    counters.forEach(counter => {
      setTimeout(() => {
        counter.style.transition = 'opacity 1s';
        counter.style.opacity = 1;
      }, 1000);
    });
  }, []);

  const accordionItems = [
    {
      title: '1% Listing for Sellers',
      content:
        'At Modern Solution Realty, sellers benefit from a cutting-edge approach with our 1% listing fee on all transactions, revolutionizing the real estate market.',
    },
    {
      title: '$5,000 Cashback for buyers',
      content:
        'Experience the ultimate advantage with Modern Solution Realty! Beyond our wealth of knowledge and outstanding services, our buyers enjoy an exciting $5000 cash-back offer! Don’t let the cost of purchasing weigh you down – our cash incentive is your key to easing the burden of moving fees, legal expenses, land-transfer fees, and more.',
    },
    {
      title: '2,500+ Properties Sold',
      content:
        'With over 2,500 properties successfully sold and counting, Modern Solution Realty demonstrates unparalleled expertise and a proven track record in the real estate industry across Ontario.',
    },
    {
      title: '$1,100,000+ buyers received in cashback',
      content:
        'Join the savvy homebuyers who have already benefitted from over $1,100,000 in cash-back and counting! Your dream home just became even more affordable with Modern Solution Realty.',
    },
    {
      title: '$20,150,000+ sellers saved on commission',
      content:
        'Through our groundbreaking 1% listing fee structure, coupled with a minimum 1% fee for the buyer’s agent, sellers have collectively saved an impressive $20,150,000+ in commission fees, showcasing the substantial benefits of choosing Modern Solution Realty for their real estate needs.',
    },
  ];

  const images = [image1, image2, image3, image4, image5, image6];

  const settings = {
    dots: false,
    infinite: true,
    speed: 6000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='selling-process-container'>
      <Helmet>
        <title>Modern Solution Realty - Sell</title>
      </Helmet>
      
      <div className='selling-process'>
        <div
          className='hero-section'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='hero-overlay'></div>
          <div className='hero-content'>
            <h1>Sell Your Home with 1% Commission</h1>
            <p>
              The housing market has dramatically changed in the last 20 years,
              with annual price increases far outpacing inflation. It’s
              increasingly difficult to justify agents receiving a 5% total
              commission.
            </p>
            
            <div className="stats-container">
              <div className="stat">
                <div className="counter" data-target="2500">0</div>
                <div className="label">Properties Sold</div>
              </div>
              <div className="stat">
                <div className="counter" data-target="1100000">0</div>
                <div className="label">Buyers Received in Cashback</div>
              </div>
              <div className="stat">
                <div className="counter" data-target="20150000">0</div>
                <div className="label">Sellers Saved on Commission</div>
              </div>
            </div>

            <button className='list-button' onClick={() => scrollToSection('contact')}>
              Let's Talk
            </button>
          </div>
        </div>
        
        <div id='about' className='we-sell-you-save'>
          <div className='left-column'>
            <h2>
              We Sell, <span className='partners-text'>You Save</span>
            </h2>
            <div className='divider'></div>
            <p>
              Unlock the full potential of your property sale with Modern
              Solution Realty!{' '}
              <span className='partners-text'>
                Since 2014, we’ve been transforming the real estate landscape in
                Southern Ontario with our innovative 1% commission. Our
                dedicated team has successfully navigated over 2,500
                transactions, saving our clients an astonishing $20 million in
                commissions.{' '}
              </span>
            </p>
            <p>
              Forget about paying high fees. With us, you get premium service,
              expert guidance, and more money in your pocket—all for just 1%
              commission. Trust in our proven track record and commitment to
              your financial success.
            </p>
            <p>
              Join the thousands who have achieved their real estate goals with
              Modern Solution Realty. Contact us today and start your journey to
              a smarter, more rewarding property sale!
            </p>
            <button className='list-button' onClick={() => scrollToSection('contact')}>
              Let's Talk
            </button>
          </div>
          <div className='right-column'>
            <div className='video-container'>
              <video width='1000px' controls poster={hannahThumbnail}>
                <source
                  src={require('../assets/videos/sell.mp4')}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className='advertising-partners animate-on-scroll'>
          <h2>
            Advertising <span className='partners-text'>Partners</span>
          </h2>
          <div className='divider'></div>
        </div>
        <div className='slider-container'>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`slide-${index}`}
                  className='slider-image'
                />
              </div>
            ))}
          </Slider>
        </div>

        <h2 id='process'>
          Let's <span className='partners-text'>Get Started</span>
        </h2>
        <div className='divider'></div>
        <div className='selling-process-slider-section'>
          <div className='selling-process-slider-container'>
            <button className='selling-process-prev' onClick={prevSlide}>
              &#10094;
            </button>
            <div className='selling-process-slides'>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`selling-process-slide ${
                    index === currentSlide ? 'selling-process-active' : ''
                  }`}
                >
                  <img src={slide.image} alt={`Slide ${index + 1}`} />
                  <h3
                    style={{
                      color: '#9CDCF8',
                      marginTop: '50px',
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      textShadow: '1px 1px 0px #577999',
                    }}
                  >
                    {slide.title}
                  </h3>
                  <p style={{ marginTop: '30px', fontFamily: 'Inter' }}>
                    {slide.text}
                  </p>
                </div>
              ))}
            </div>
            <button className='selling-process-next' onClick={nextSlide}>
              &#10095;
            </button>
          </div>
        </div>
        <GoogleReviews />
        <div id='testimonials' className='testimonial-slider-wrapper'>
          <h2>Client Testimonials</h2>
          <div className='divider'></div>
          <div className='testimonial-slider-section'>
            <button
              className='testimonial-slider-prev'
              onClick={prevTestimonial}
            >
              &#10094;
            </button>
            <div className='testimonial-slider-container'>
              <div className='testimonial-slides'>
                {testimonials.map((testimonial, index) => (
                  <div
                    key={index}
                    className={`testimonial-slide ${
                      index === currentTestimonial ? 'testimonial-active' : ''
                    }`}
                    style={{
                      backgroundImage: `url(${testimonial.thumbnail})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    onClick={() => openLightbox(testimonial.video)}
                  >
                    <div className='play-button' />
                  </div>
                ))}
              </div>
            </div>
            <button
              className='testimonial-slider-next'
              onClick={nextTestimonial}
            >
              &#10095;
            </button>
            <div className='testimonial-slider-dots'>
              {testimonials.map((_, index) => (
                <span
                  key={index}
                  className={`testimonial-dot ${
                    index === currentTestimonial ? 'testimonial-active' : ''
                  }`}
                  onClick={() => selectTestimonial(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
        {lightboxVideo && (
          <div className='lightbox' onClick={closeLightbox}>
            <div className='lightbox-content'>
              <iframe
                width='800'
                height='500'
                src={`${lightboxVideo}?autoplay=1`}
                title='Testimonial Video'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        <div id='save-more' className='two-column-section'>
          <div className='left-column'>
            <h2>Save More with Modern Solution</h2>
            <h3>
              The real estate industry has changed. Here’s how we can pass the
              savings on to you.
            </h3>
            <div className='divider'></div>
            <div className='accordion'>
              {accordionItems.map((item, index) => (
                <div key={index} className='accordion-item'>
                  <div
                    className='accordion-header'
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className='accordion-icon'>
                      <img src={iconMap[item.title]} alt={item.title} />
                    </div>
                    <div className='accordion-title'>{item.title}</div>
                    <div className='accordion-arrow'>
                      {openAccordion === index ? '↑' : '↓'}
                    </div>
                  </div>
                  {openAccordion === index && (
                    <div className='accordion-content'>
                      <p>{item.content}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <button className='list-button' onClick={() => scrollToSection('contact')}>
              Commission Explained
            </button>
          </div>
          <div className='right-column'>
            <h2 id='contact' className='contact-form-title'>
              Buy With Us Today. Talk to an Agent.
            </h2>
            <div className='divider'></div>
            <h3>
              Use the form below to send us an email. We'll get back to you as
              soon as we can.
            </h3>
            <div className='contact-form'>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className='local-realtor-expert'>
          <div className='left-column'>
            <h2>
              Local <span className='partners-text'>Realtor Expert</span>
            </h2>
            <div className='divider'></div>
            <p>
              Discover the perfect match with our local and highly experienced
              realtors, experts in your neighborhood’s real estate landscape.
              Our knowledgeable agents bring unparalleled expertise, providing{' '}
              <b>
                <span className='partners-text'>24/7 client service</span>
              </b>{' '}
              tailored to your schedule, needs, and desires. With a deep
              understanding of both buyers and their agents, we gain a unique
              advantage when listing your home for just 1%. Our top 1% real
              estate agents consistently outperform the average agent, achieving
              sales figures that are five times higher!
            </p>
            <p>
              Experience a stress-free journey with our seamless{' '}
              <b>
                <span className='partners-text'>1% listing fee</span>
              </b>
              . Trust us to handle every detail effortlessly, ensuring you enjoy
              a hassle-free real estate experience.
            </p>
          </div>
          <div className='right-column'>
            <img
              src={rightImage}
              alt='Local Realtor Expert'
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </div>
        </div>
        <div className='full-width-section'>
          <h2>
            <span className='partners-text'>Modern Solution Realty</span> vs
            Other Realty Brokerages
          </h2>
          <div className='divider'></div>
          <div className='charts-container'>
            <div id='map-container'>
              <div id='overlay'>
                <div className='overlay-content'>
                  <div className='overlay-text'>
                    Interactive Chart - Hover/Click to see data
                  </div>
                </div>
              </div>
              <div id='myCommissionChart'></div>
            </div>
            <div id='homePriceChart'></div>
          </div>
        </div>
        <div className='featured-listings'>
          <ListingModule listingNumbers={listingNumbers} />
        </div>
        <CallToActionBottomRight />
        <Footer />
      </div>
    </div>
  );
};

export default SellingPage;
