import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './NavbarHome.css';
import logo from '../logo.webp';
import msrLogo from '../assets/images/ms.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const NavbarHome = ({ detectedCity }) => {
  const [isScrolled, setScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSellDropdownOpen, setSellDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const sellDropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsSmallScreen(width <= 1230);
      setIsMobileView(width <= 768); // Set mobile view for tablets and below
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileView && sellDropdownRef.current && !sellDropdownRef.current.contains(event.target)) {
        setSellDropdownOpen(false);
      }
    };

    if (isMobileView) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileView]);

  const toggleSellDropdown = (e) => {
    if (isMobileView) {
      e.preventDefault();
      setSellDropdownOpen(!isSellDropdownOpen);
    }
  };

  const searchLink = detectedCity ? `/listings?city=${detectedCity}` : '/listings';

  return (
    <nav className={`navbar-home ${isScrolled ? 'navbar-home-scrolled' : ''}`}>
      <div className="navbar-home-container">
        <div className="navbar-home-logo-container">
          <NavLink to="/" className="navbar-home-logo-link">
            <img
              src={isSmallScreen ? msrLogo : logo}
              alt="Modern Solution Realty"
              className="navbar-home-logo-image"
            />
          </NavLink>
        </div>

        <div className="nav-links-container">
          <ul className="navbar-home-nav-links">
            <li><NavLink to="/buy" activeClassName="navbar-home-active-link">Buy</NavLink></li>
            <li className={`sell-nav-item ${isMobileView ? 'mobile' : ''}`} ref={sellDropdownRef}>
              <div 
                className={`sell-link-container ${isMobileView ? 'mobile' : ''}`}
                onClick={isMobileView ? toggleSellDropdown : undefined}
                role={isMobileView ? "button" : undefined}
                tabIndex={isMobileView ? 0 : undefined}
              >
                <NavLink 
                  to="/sell" 
                  activeClassName="navbar-home-active-link"
                  onClick={(e) => isMobileView && e.preventDefault()}
                >
                  Sell
                </NavLink>
                <span className="sell-dropdown-toggle">
                  <FontAwesomeIcon 
                    icon={faChevronDown} 
                    size="xs" 
                    className={isMobileView && isSellDropdownOpen ? 'rotate-180' : ''}
                  />
                </span>
              </div>
              <ul className={`sell-dropdown-menu ${isMobileView && isSellDropdownOpen ? 'show' : ''}`}>
                <li><NavLink to="/sell/pricing-your-home" activeClassName="navbar-home-active-link">Pricing Your Home</NavLink></li>
              </ul>
            </li>
            <li><NavLink to={searchLink} activeClassName="navbar-home-active-link">Search</NavLink></li>
            <li className="navbar-home-extra-link"><NavLink to="/commission-explained" activeClassName="navbar-home-active-link">Commission Explained</NavLink></li>
            <li className="navbar-home-extra-link"><NavLink to="/articles" activeClassName="navbar-home-active-link">Articles</NavLink></li>
            <li className="navbar-home-extra-link"><NavLink to="/contact" activeClassName="navbar-home-active-link">Contact</NavLink></li>
            <li className="navbar-home-dropdown-container">
              <span className="navbar-home-dropdown-toggle">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
              <ul className="navbar-home-dropdown-menu">
                <li><NavLink to="/commission-explained" activeClassName="navbar-home-active-link">Commission Explained</NavLink></li>
                <li><NavLink to="/articles" activeClassName="navbar-home-active-link">Articles</NavLink></li>
                <li><NavLink to="/contact" activeClassName="navbar-home-active-link">Contact</NavLink></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarHome;